import React, { Component } from 'react'
import { Link, Route, Redirect } from 'react-router-dom'
import Routes from './Routes'
import axios from 'axios'
import config from './config'
import * as queryString from 'query-string'

class App extends Component {

  constructor (props) {
    super(props)
  }

  render () {
    const shop_params = queryString.parse(window.location.search.substring(1))
    if (!shop_params.shop) return null;
    if (shop_params.shop.startsWith() == "bjorntech") {
      axios.defaults.baseURL = config.bjorntech.API_URL_DEV;
    } else {
      axios.defaults.baseURL = config.bjorntech.API_URL;
    }
    console.log(shop_params)
    if (!shop_params.client_id) {
      window.location.replace(`${axios.defaults.baseURL}/shopify-authorize?hmac=${shop_params.hmac}&shop=${shop_params.shop}&timestamp=${shop_params.timestamp}`)
      return null;
    } else {
      const childProps = {
        shopifyApiKey: shop_params.client_id,
        shopifyShop: shop_params.shop,
        axiosInstance: axios
      }
      return (
        <div className='App container'>
          <Routes childProps={childProps} />
        </div>
      )
    }
  }

}

export default App

